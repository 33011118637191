<template>
  <div class="banners" v-if="banners.length > 0">
    <VueSlickCarousel
      :arrows="false"
      :dots="true"
      :infinite="true"
      :autoplay="true"
      :autoplaySpeed="5000"
      :adaptiveHeight="true"
    >
      <div v-for="banner in banners" :key="banner.id">
        <template v-if="isUrl(banner.url)">
          <a :href="banner.url" target="_blank">
            <img :src="banner.imageUrl" />
          </a>
        </template>
        <template v-else>
          <a :href="`mailto:${banner.url}`" target="_blank">
            <img :src="banner.imageUrl" />
          </a>
        </template>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "BannerComp",
  components: {
    VueSlickCarousel,
  },
  props: {
    banners: {
      type: Array,
    },
  },
  methods: {
    isUrl(url) {
      return url.startsWith("http");
    },
  },
};
</script>

<style scoped>
.banners {
  width: 90%;
  margin: 0 auto;
}
</style>
