import Vue from 'vue'
import VueI18n from 'vue-i18n'

let locale = 'en',
  message = {} // default: "en"
const language = window.navigator.language
if (language) {
  const lang = language.toLowerCase().split('-')
  if (lang[0] == 'ja') {
    locale = lang[0]
  } else if (lang[0] == 'fr') {
    locale = lang[0]
  }
}
message[locale] = require(`./assets/i18n/${locale}.json`)

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale,
  messages: message,
})
