<template>
  <p class="white-space-pre">
    <img src="@/assets/img/place_black.svg" /> <br />
    {{ $t("location.needGps1") }}
    <br /><a
      href="https://www.cheerphone.jp/howto-%E4%BD%8D%E7%BD%AE%E6%83%85%E5%A0%B1%E8%A8%AD%E5%AE%9A%E6%96%B9%E6%B3%95"
      target="_blank"
    >
      {{ $t("location.needGps2") }}</a
    >{{ $t("location.needGps3") }}
  </p>
</template>

<script>
export default {
  name: "HowtoEnableGps",
  mounted() {
    document.body.className = "p-notfound";
    this.$root.headerHide = true;
    this.$root.footerHide = true;
  },
  beforeDestroy() {
    document.body.className = "";
    this.$root.headerHide = false;
    this.$root.footerHide = false;
  },
};
</script>
