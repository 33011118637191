import Vue from 'vue'
import VueRouter from 'vue-router'
import Audience from '../views/Audience.vue'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import LocationFailed from '../views/LocationFailed.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/audience',
        name: 'Audience',
        component: Audience
    },
    {
        path: '/location_error',
        name: 'LocationFailed',
        component: LocationFailed
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router