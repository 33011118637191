<template>
  <div>
    <template v-if="imageUrl && message.length > 0">
      <image-message-notification :message="message" :image="imageUrl" />
    </template>
    <template v-else-if="imageUrl">
      <image-notification :image="imageUrl" />
    </template>
    <template v-else-if="message.length > 0">
      <message-notification :message="message" />
    </template>
  </div>
</template>

<script>
import ImageMessageNotification from './notifications/ImageMessageNotification.vue'
import ImageNotification from './notifications/ImageNotification.vue'
import MessageNotification from './notifications/MessageNotification.vue'

export default {
  name: 'PushMessageComp',
  components: {
    ImageMessageNotification,
    ImageNotification,
    MessageNotification,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
  },
  data: () => {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
