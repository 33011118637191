<template>
  <div>
    <div class="popup modal is-active">
      <div class="popup__inner">
        <h2 class="popup__title">{{ $t('location.outsideTitle') }}</h2>
        <p class="popup__text">
          {{ $t('location.outsideMessage') }}
        </p>
        <p class="popup__text">
          {{ $t('location.outsideIntaraction1') }}
          <template v-if="$i18n.locale == 'ja'">
            <a
              href="https://www.cheerphone.jp/howto-%E4%BD%8D%E7%BD%AE%E6%83%85%E5%A0%B1%E8%A8%AD%E5%AE%9A%E6%96%B9%E6%B3%95"
              target="_blank"
              >{{ $t('location.outsideIntaraction2') }}</a
            >
          </template>
          <template v-else>
            <a href="https://www.cheerphone.jp/howtosetgps-en" target="_blank">{{
              $t('location.outsideIntaraction2')
            }}</a>
          </template>
          {{ $t('location.outsideIntaraction3') }}
        </p>
        <p class="privacy-policy">
          <a target="_blank" href="https://www.cheerphone.jp/privacy-policy">{{
            $t('common.privacyPolicy')
          }}</a>
        </p>
      </div>
    </div>
    <div id="modal-overlay" class=""></div>
  </div>
</template>

<script>
export default {
  name: 'OuterLocation',
}
</script>

<style></style>
