<template>
  <p class="white-space-pre">{{ $t("error.locationErrorMessage") }}</p>
</template>

<script>
export default {
  name: "LocationFailed",
  mounted() {
    document.body.className = "p-notfound";
    this.$root.headerHide = true;
    this.$root.footerHide = true;
  },
  beforeDestroy() {
    document.body.className = "";
    this.$root.headerHide = false;
    this.$root.footerHide = false;
  },
};
</script>
