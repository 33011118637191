<template>
  <div class="popup modal gps-msg is-active">
    <div class="popup__inner watch-end">
      <a href class="modal-heading__btn btn-close" @click.stop.prevent="onClose">
        <i class="fas fa-times"></i>
      </a>
      <p class="popup__text bold white-space-pre">
        {{ $t("rating.thanksMessage") }}
      </p>
      <div v-for="ad in ads" :key="ad.id" class="ad">
        <p class="ad__title">{{ ad.title }}</p>
        <a :href="ad.url" target="_blank">
          <img :src="ad.imageUrl" :alt="ad.title" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WatchEndDialog",
  props: {
    bugReportUrl: {
      type: String,
    },
    ads: {
      type: Array,
    },
  },
  data: () => {
    return {
      rating: 3,
      sended: false,
    };
  },
  methods: {
    onSelected() {
      console.log("onSelected");
      this.$emit("rating", this.rating);
    },
    onClose() {
      this.$emit("close", this.rating);
    },
    sentSuccess() {
      this.sended = true;
    },
  },
};
</script>

<style>
.ad {
  margin-bottom: 10px;
}
.ad a img {
  height: 200px;
}

.ad .ad__title {
  font-size: 1.5rem;

  color: black;
  margin-bottom: 0.5rem;
}
</style>
