import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import normalized_css from './assets/css/normalize.css'
import all_min_css from './assets/css/all.min.css'
import style_css from './assets/css/style.css'
import VuejsDialog from 'vuejs-dialog'
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import './lib/firebase'
import dayjs from 'dayjs'
import VueHead from 'vue-head'
import { i18n } from './i18n.js'
import autolinker, { AUTOLINKER_NAME } from 'vue-autolinker'
import VueGtag from 'vue-gtag'

// Tell Vue to install the plugin.
Vue.use(VuejsDialog)
Vue.use(VueHead)

Vue.config.productionTip = false

dayjs.locale('ja')
Vue.prototype.$dayjs = dayjs

Vue.directive(AUTOLINKER_NAME, autolinker)

Vue.filter('truncate', function (value, length, omission) {
  var len = length ? parseInt(length, 10) : 20
  var ommision = omission ? omission.toString() : '...'
  if (value.length <= length) {
    return value
  }

  return value.substring(0, len) + ommision
})

Vue.use(VueGtag, {
  config: {
    id: 'G-5KFYH6S07C',
    params: {
      send_page_view: false,
    },
  },
})

new Vue({
  router,
  store,
  normalized_css,
  all_min_css,
  style_css,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
