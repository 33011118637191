<template>
  <div v-if="room">
    <header>
      <div class="container">
        <h1 class="room-name">{{ room.name }}</h1>
        <p class="description">{{ $t('login.questionnaireMessage') }}</p>
      </div>
    </header>
    <main>
      <div class="container">
        <section id="questionnaire" class="questionnaire">
          <div v-if="room.requireGender">
            <h2>性別</h2>
            <div class="questionnair-list">
              <label v-for="g in genderOptions" :key="g.value">
                <input v-model="gender" type="radio" :value="g.value" name="gender" />
                <span class="btn btn-outline btn-no-shadow">{{ g.name }}</span>
              </label>
            </div>
          </div>
          <div v-if="room.requireGeneration">
            <h2>年代</h2>
            <div class="questionnair-list">
              <label v-for="g in generationOptions" :key="g.value">
                <input
                  v-model.number="generation"
                  type="radio"
                  :value="g.value"
                  name="generation"
                />
                <span class="btn btn-outline btn-no-shadow">{{ g.name }}</span>
              </label>
            </div>
          </div>
          <div class="questionnair__mail" v-if="room.requireEmail">
            <h2>{{ $t('login.emailAddress') }}</h2>
            <input v-model.trim="email" type="text" placeholder />
          </div>
          <div class="questionnair__mail" v-if="room.requireExtra">
            <h2>{{ room.extraRequirementText }}</h2>
            <input v-model.trim="extra" type="text" placeholder />
          </div>
          <div class="questionnair__mail" v-if="room.requireExtra2">
            <h2>{{ room.extraRequirementText2 }}</h2>
            <input v-model.trim="extra2" type="text" placeholder />
          </div>
          <div class="questionnair__mail" v-if="room.requireExtra3">
            <h2>{{ room.extraRequirementText3 }}</h2>
            <input v-model.trim="extra3" type="text" placeholder />
          </div>
          <div>
            <button class="btn btn-primary" :disabled="!isValid" @click="onEnter">
              {{ $t('login.enter') }}
            </button>
          </div>
        </section>
        <!-- //#enter -->
      </div>
    </main>

    <error-dialog
      v-if="isErrorDialogOpen"
      :title="errorDialog.title"
      :message="errorDialog.message"
      @close="isErrorDialogOpen = false"
    />
  </div>
</template>

<script>
/* eslint-disable */
import RoomRequest from '@/net/room'
import { genderOptions, generationOptions } from '@/utils/options'
//import store from "@/store";
import ErrorDialog from '../components/ErrorDialog.vue'
import { validateEmail } from '../utils/validater'

export default {
  name: 'Login',
  components: {
    ErrorDialog,
  },
  data: () => {
    return {
      room: null,
      email: '',
      extra: '',
      extra2: '',
      extra3: '',
      gender: undefined,
      generation: undefined,
      genderOptions: genderOptions,
      generationOptions: generationOptions,
      isErrorDialogOpen: false,
      errorDialog: {
        title: '',
        message: '',
      },
    }
  },
  computed: {
    teamUUID: function () {
      return this.$route.query.team
    },
    roomUUID: function () {
      return this.$route.query.room
    },
    locationCode: function () {
      return this.$route.query.locationCode
    },
    queries: function () {
      let query = {
        team: this.teamUUID,
        room: this.roomUUID,
      }
      if (this.locationCode && this.locationCode.length > 0) {
        query['locationCode'] = this.locationCode
      }
      return query
    },
    roomRequest: function () {
      return new RoomRequest(this.teamUUID, this.roomUUID)
    },
    isValid: function () {
      var valid = true
      if (this.room.requireGender) {
        valid = valid && this.gender !== undefined ? true : false
      }
      if (this.room.requireGeneration) {
        valid = valid && this.generation !== undefined ? true : false
      }
      if (this.room.requireEmail) {
        valid = valid && this.email.length > 0 && validateEmail(this.email) ? true : false
      }
      if (this.room.requireExtra) {
        valid = valid && this.extra.length > 0 ? true : false
      }
      if (this.room.requireExtra2) {
        valid = valid && this.extra2.length > 0 ? true : false
      }
      if (this.room.requireExtra3) {
        valid = valid && this.extra3.length > 0 ? true : false
      }
      return valid
    },
    params: function () {
      var params = {}
      if (this.room.requireGender) {
        params['gender'] = this.gender
      }
      if (this.room.requireGeneration) {
        params['generation'] = this.generation
      }
      if (this.room.requireEmail) {
        params['email'] = this.email
      }
      if (this.room.requireExtra) {
        params['extra'] = this.extra
      }
      if (this.room.requireExtra2) {
        params['extra2'] = this.extra2
      }
      if (this.room.requireExtra3) {
        params['extra3'] = this.extra3
      }
      return params
    },
  },
  created: async function () {
    await this.loadRoom()
    this.$gtag.pageview({
      page_title: this.room.name + ' - ログイン',
      page_location: location.href,
      page_path: '/login',
    })
  },
  mounted() {
    document.body.className = 'p-questionnaire p-audience'
    this.$root.headerHide = true
    this.$root.footerHide = true
  },
  beforeDestroy() {
    document.body.className = ''
    this.$root.headerHide = false
    this.$root.footerHide = false
  },
  methods: {
    loadRoom: async function () {
      try {
        this.room = await this.roomRequest.fetch()
      } catch (e) {
        this.$router.push({
          name: 'NotFound',
        })
      }
    },
    onEnter: async function () {
      try {
        const user = await this.roomRequest.enter(this.params)
        this.$store.dispatch('saveUser', user)
        this.$router.push({
          name: 'Audience',
          query: this.queries,
        })
      } catch (e) {
        this.handleError(e.message)
      }
    },
    handleError: function (message) {
      this.errorDialog = {
        title: 'エラー',
        message: message,
      }
      this.isErrorDialogOpen = true
    },
  },
}
</script>
