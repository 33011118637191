import AgoraRTC from "agora-rtc-sdk-ng";
import EventEmitter from 'events'

export const DistributionEvents = {}

export default class ListenerClient extends EventEmitter {
    constructor() {
        super();
        this.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
        this.uid = undefined;
        this.remoteUsers = {};
    }

    async start(appId, channel, token, uid) {
        this.client.on("user-published", this.eventUserPublished.bind(this));
        this.client.on("user-unpublished", this.eventUserUnpublished.bind(this));

        this.uid = await Promise.all([
            this.client.join(
                appId, channel, token, uid
            )
        ])

        await this.client.setClientRole("audience", { level: 2 });
    }

    // https://agoraio-community.github.io/AgoraWebSDK-NG/docs/en/publish_and_subscribe#subscribe-to-audio-and-video-tracks
    async eventUserPublished(user, mediaType) {
        try {
            this.remoteUsers[user.uid] = user;
            await this.client.subscribe(user, mediaType);
            if (mediaType == "audio") {
                const audioTrack = user.audioTrack;
                audioTrack.setVolume(200);
                audioTrack.play();
            }
        } catch (e) {
            console.error(e);
        }
    }

    end() {
        if (this.client) {
            this.client.off("user-published")
            this.client.off("user-unpublished")
            this.client.leave();
            this.remoteUsers = {};
        }
    }

    eventUserJoined(user) {
        this.remoteUsers[user.uid] = user;
    }

    eventUserLeft(user) {
        delete this.remoteUsers[user.id];
    }

    async eventUserUnpublished(user) {
        this.eventUserLeft(user);
    }
}