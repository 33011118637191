<template>
  <div>
    <howto-enable-gps v-if="showHowtoEnableGps" />
    <outer-location-dialog v-if="isOuterLocationDialogOpened" />
  </div>
</template>

<script>
//import store from "@/store";
import RoomRequest from '@/net/room'
import HowtoEnableGps from '../components/HowtoEnableGps.vue'
import OuterLocationDialog from '../components/OuterLocationDialog.vue'

export default {
  name: 'Index',
  components: {
    HowtoEnableGps,
    OuterLocationDialog,
  },
  data: () => {
    return {
      room: undefined,
      isOuterLocationDialogOpened: false,
      showHowtoEnableGps: false,
      errorDialog: {
        title: '',
        message: '',
      },
    }
  },
  computed: {
    teamUUID: function () {
      return this.$route.query.team
    },
    roomUUID: function () {
      return this.$route.query.room
    },
    locationCode: function () {
      return this.$route.query.locationCode
    },
    queries: function () {
      let query = {
        team: this.teamUUID,
        room: this.roomUUID,
      }
      if (this.locationCode && this.locationCode.length > 0) {
        query['locationCode'] = this.locationCode
      }
      return query
    },
    roomRequest: function () {
      return new RoomRequest(this.teamUUID, this.roomUUID)
    },
    needLogin: function () {
      if (
        this.room &&
        (this.room.requireEmail ||
          this.room.requireGender ||
          this.room.requireGeneration ||
          this.room.requireExtra)
      ) {
        return true
      }
      return false
    },
  },
  async created() {
    this.$store.dispatch('loadUser')
    await this.loadRoom()
    this.$gtag.pageview({
      page_title: this.room.name + ' - Index',
      page_location: location.href,
      page_path: '/',
    })
  },
  methods: {
    loadRoom: async function () {
      try {
        this.room = await this.roomRequest.fetch()
        if (this.room.useGps && this.locationCode != this.room.locationCode) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              function (position) {
                if (
                  !this.innerDetection(
                    this.room.latitude,
                    this.room.longitude,
                    position.coords.latitude,
                    position.coords.longitude,
                    this.room.radius
                  )
                ) {
                  this.outerLocation()
                } else {
                  this.detectRoom()
                }
              }.bind(this),
              function (error) {
                this.needLocation()
                console.error(error)
              }.bind(this)
            )
          } else {
            this.needLocation()
          }
        } else {
          this.detectRoom()
        }
      } catch (e) {
        this.$router.push({
          name: 'NotFound',
        })
      }
    },
    detectRoom: async function () {
      if (this.$store.getters['isAvailable']) {
        try {
          await this.roomRequest.fetchUser(this.$store.getters.uuid)
          this.$router.push({
            name: 'Audience',
            query: this.queries,
          })
          return
        } catch (e) {
          console.log(e)
        }
      }
      if (this.needLogin) {
        this.$router.push({
          name: 'Login',
          query: this.queries,
        })
      } else {
        try {
          let user = await this.roomRequest.enter({})
          this.$store.dispatch('saveUser', user)
          this.$router.push({
            name: 'Audience',
            query: this.queries,
          })
        } catch (e) {
          this.$router.push({
            name: 'NotFound',
          })
        }
      }
    },
    needLocation: function () {
      this.showHowtoEnableGps = true
    },
    outerLocation: function () {
      this.isOuterLocationDialogOpened = true
    },
    innerDetection: function (lat1, lng1, lat2, lng2, radius) {
      const radian = function (degree) {
        return (degree * Math.PI) / 180.0
      }
      const distance = function (lat1, lng1, lat2, lng2) {
        return (
          6371.0 *
          Math.acos(
            Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) +
              Math.sin(lat1) * Math.sin(lat2)
          )
        )
      }
      return (
        distance(radian(lat1), radian(lng1), radian(lat2), radian(lng2)) * 1000.0 <=
        radius
      )
    },
  },
}
</script>
