<template>
  <div v-if="room">
    <header>
      <div class="container">
        <div v-if="room.useBanner && !room.bannerUnderRoomImage" class="banner_top">
          <banner-comp :banners="banners" />
        </div>
        <div class="room-img">
          <img :src="room.imageUrl" :alt="$t('audience.roomImageAlt')" />
        </div>
        <h1 class="room-name">{{ room.name }}</h1>
        <p class="description" v-html="room.description"></p>
        <div v-if="room.useBanner && room.bannerUnderRoomImage">
          <banner-comp :banners="banners" />
        </div>
      </div>
    </header>
    <main>
      <div class="container">
        <section id="channel" class="channel">
          <h2 class="heading_title">{{ $t('audience.channelList') }}</h2>
          <p class="heading_text">{{ $t('audience.channelSelectMessage') }}</p>

          <div class="channel__list">
            <div
              v-for="channel in channels"
              class="ch-list__item"
              :key="channel.uuid"
              :class="{
                'is-active': channel.onAir,
                closed: !channel.onAir,
              }"
            >
              <a href @click.prevent.stop="onSelectChannel(channel)">
                <div class="onair-head_detail" v-if="channel.onAir">
                  <span class="status">LIVE</span>
                </div>

                <div class="ch-list__thumb">
                  <template v-if="channel.displayIcon">
                    <img
                      :src="channel.iconUrl"
                      :alt="channel.name"
                      class="ch-list__icon"
                    />
                  </template>
                  <template v-else>
                    <img
                      v-for="distributor in channel.distributors"
                      :key="distributor.uuid"
                      :src="distributor.imageUrl"
                      :alt="distributor.name"
                    />
                  </template>
                </div>

                <div class="ch-list__detail">
                  <h3 class="ch-list__name">{{ channel.name }}</h3>
                  <p class="ch-list__description" v-html="channel.description"></p>
                  <div class="ch-list__meta">
                    <div class="ch-list__meta-count">
                      <div class="view">
                        <i class="fas fa-users"></i>
                        <span>{{ channel.audienceNumber }}</span>
                      </div>
                      <div class="heart">
                        <i class="fas fa-heart"></i>
                        <span>{{ channel.like }}</span>
                      </div>
                    </div>
                    <a
                      href
                      class="btn btn-primary btn-ss"
                      v-if="
                        (!selectedChannel || selectedChannel.uuid == channel.uuid) &&
                        channel.onAir
                      "
                      >{{ $t('audience.startListening') }}</a
                    >
                  </div>
                </div>
              </a>
            </div>
            <!-- /.ch-list__item -->
          </div>
        </section>
        <div class="footer_notice fixed" v-if="room.questionnaireLabel">
          <a :href="questionnaireUrl">{{ room.questionnaireLabel }}</a>
        </div>
      </div>
      <channel-comp
        :teamUUID="teamUUID"
        :roomUUID="roomUUID"
        :channel="selectedChannel"
        :banners="banners"
        :useBanner="selectedChannel.useBanner"
        v-if="selectedChannel"
        @exit="onExitChannel"
      />
      <notice-dialog
        v-if="isNoticeDialogOpend"
        :room="room"
        @close="onClickNoticeDialog"
      ></notice-dialog>
      <access-code-dialog
        v-if="isAccessCodeOpend"
        @ok="velifyAccessCocde"
        @cancel="isAccessCodeOpend = false"
      ></access-code-dialog>
      <error-dialog
        v-if="isErrorDialogOpened"
        :title="errorDialog.title"
        :message="errorDialog.message"
        @close="isErrorDialogOpened = false"
      />
    </main>
  </div>
</template>

<script>
import RoomRequest from '@/net/room'
import { validateEmail } from '../utils/validater'
import ChannelComp from '../components/ChannelComp.vue'
import NoticeDialog from '../components/NoticeDialog.vue'
import AccessCodeDialog from '../components/AccessCodeDialog.vue'
import ChannelRequest from '@/net/channel'
import ErrorDialog from '../components/ErrorDialog.vue'
import useUtility from '../lib/util'
import BannerComp from '../components/BannerComp.vue'

//import store from "@/store";

export default {
  name: 'Audience',
  components: {
    ChannelComp,
    NoticeDialog,
    AccessCodeDialog,
    ErrorDialog,
    BannerComp,
  },
  data: () => {
    return {
      room: null,
      channels: [],
      banners: [],
      candidateChannel: undefined,
      selectedChannel: undefined,
      isNoticeDialogOpend: false,
      statusTimer: null,
      isAccessCodeOpend: false,
      isErrorDialogOpened: false,
      errorDialog: {
        title: '',
        message: '',
      },
    }
  },
  mounted() {
    document.body.className = 'p-room p-audience'
    this.$root.headerHide = true
    this.$root.footerHide = true
    var self = this
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState !== 'visible') {
        self.stopTimer()
      } else {
        self.startTimer()
      }
    })
  },
  beforeDestroy() {
    this.stopTimer()
    document.body.className = ''
    document.body.style = ''
    this.$root.headerHide = false
    this.$root.footerHide = false
  },
  computed: {
    teamUUID: function () {
      return this.$route.query.team
    },
    roomUUID: function () {
      return this.$route.query.room
    },
    locationCode: function () {
      return this.$route.query.locationCode
    },
    queries: function () {
      let query = {
        team: this.teamUUID,
        room: this.roomUUID,
      }
      if (this.locationCode && this.locationCode.length > 0) {
        query['locationCode'] = this.locationCode
      }
      return query
    },
    roomRequest: function () {
      return new RoomRequest(this.teamUUID, this.roomUUID)
    },
    questionnaireUrl: function () {
      if (validateEmail(this.room.questionnaireUrl)) {
        return `mailto:${this.room.questionnaireUrl}`
      } else {
        return this.room.questionnaireUrl
      }
    },
  },
  watch: {
    selectedChannel: function (value) {
      if (value) {
        this.stopTimer()
      } else {
        this.startTimer()
      }
    },
  },
  async created() {
    this.$store.dispatch('loadUser')
    this.$store.dispatch('loadRoomNotes')
    if (this.$store.getters.isAvailable) {
      await this.loadRoom()
      await this.loadChannels(true)
      this.banners = await this.roomRequest.listBanners()
      const note = this.$store.getters.readRoomNotes
      if (note.length == 0 || note.indexOf(this.roomUUID) < 0) {
        this.isNoticeDialogOpend = true
      }
      this.startTimer()

      this.$gtag.pageview({
        page_title: this.room.name + ' - 視聴画面',
        page_location: location.href,
        page_path: '/audience',
      })
    } else {
      this.$router.push({
        name: 'Index',
        query: this.queries,
      })
    }
  },
  methods: {
    loadRoom: async function () {
      try {
        this.room = await this.roomRequest.fetch()
        document.body.style.background = this.room.backgroundColor
        document.body.style.color = this.room.textColor
      } catch (e) {
        this.$router.push({
          name: 'NotFound',
        })
      }
    },
    loadChannels: async function (created) {
      try {
        let res = await this.roomRequest.listChannels()
        if (created) {
          this.channels = res.channels
        } else {
          // 更新されたチャンネルのうち、onAirだけを更新する。
          this.channels.forEach(function (channel) {
            res.channels.forEach(function (current) {
              if (channel.uuid == current.uuid && channel.onAir != current.onAir) {
                channel.onAir = current.onAir
              }
            })
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    startTimer: function () {
      // ポーリングして状態を取得する。
      if (!this.statusTimer && !this.selectedChannel) {
        this.statusTimer = setInterval(
          async function () {
            await this.loadChannels()
          }.bind(this),
          10000
        )
      }
    },
    stopTimer: function () {
      if (this.statusTimer) {
        clearInterval(this.statusTimer)
        this.statusTimer = null
      }
    },
    onSelectChannel: function (channel) {
      if (channel.needAccessCode) {
        this.candidateChannel = channel
        this.isAccessCodeOpend = true
      } else {
        this.selectedChannel = channel
        this.$gtag.event('click', {
          event_category: 'channel_click',
          event_label: channel.name,
        })
      }
    },
    velifyAccessCocde: async function (accessCode) {
      this.isAccessCodeOpend = false
      try {
        let channelRequest = new ChannelRequest(
          this.teamUUID,
          this.roomUUID,
          this.candidateChannel.uuid
        )
        await channelRequest.checkAccessCode(accessCode)
        this.selectedChannel = this.candidateChannel
        this.candidateChannel = undefined
      } catch (e) {
        console.log(e)
        this.errorDialog = {
          title: '',
          message: 'アクセスコードが一致しません。ご確認ください。',
        }
        this.isErrorDialogOpened = true
      }
    },
    onExitChannel: async function () {
      this.selectedChannel = undefined
      await this.loadChannels()
    },
    onClickNoticeDialog: async function () {
      await this.$store.dispatch('readRoomNote', { uuid: this.roomUUID })
      this.isNoticeDialogOpend = false
    },
    autoLink: function (text) {
      return useUtility().autoLink(text)
    },
  },
  head: {
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0,user-scalable=no',
      },
    ],
  },
}
</script>

<style>
main .footer_notice.fixed {
  padding: 8px 8px 10px !important;
}

.channel {
  padding-bottom: 200px !important;
}
.banner_top {
  margin-bottom: 30px;
}
.ch-list__icon {
  width: 100% !important;
}
</style>
