<template>
  <div v-if="room">
    <div class="popup modal is-active">
      <div class="popup__inner room-notice">
        <a href class="modal-heading__btn btn-close" @click.stop.prevent="onClose">
          <i class="fas fa-times"></i>
        </a>
        <div class="ch__main">
          <div class="ch__head">
            <h3 class="ch__name">
              <p>{{ $t('caution.title') }}</p>
            </h3>
            <div class="popup__text caution__message">
              <template v-if="room.cautionMessage.length > 0">
                <span v-html="room.cautionMessage"></span>
              </template>
              <template v-else>
                <span class="white-space-pre">{{ $t('caution.defaultMessage') }} </span>
              </template>
            </div>
            <button
              class="btn btn-primary btn-m margin-auto"
              @click.stop.prevent="onClose"
            >
              OK
            </button>
          </div>
          <p class="privacy-policy">
            <template v-if="$i18n.locale === 'ja'">
              <a target="_blank" href="https://www.cheerphone.jp/privacy-policy">{{
                $t('common.privacyPolicy')
              }}</a>
              <br />
              <a target="_blank" href="https://www.cheerphone.jp/terms-of-use">{{
                $t('common.termsOfUse')
              }}</a>
            </template>
            <template v-else>
              <a target="_blank" href="https://www.cheerphone.jp/privacy-policy-en">{{
                $t('common.privacyPolicy')
              }}</a>
              <br />
              <a target="_blank" href="https://www.cheerphone.jp/termsofuse-en">{{
                $t('common.termsOfUse')
              }}</a>
            </template>
          </p>
        </div>
      </div>
    </div>
    <div id="modal-overlay" class></div>
  </div>
</template>

<script>
export default {
  name: 'NoticeDialog',
  props: {
    room: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.caution__message {
  white-space: pre-wrap;
}
</style>
