import { ref, push, set, onChildAdded, off } from "firebase/database";
import { db, auth } from "@/lib/firebase";

const useMessage = (channelUuid) => {
    const dbRef = ref(db, channelUuid)
    const dbAcceptRef = ref(db, `${channelUuid}-accept`)

    async function send(name, message) {
        const postRef = push(dbRef)
        await set(postRef, {
            uid: auth.currentUser ? auth.currentUser.uid : null,
            name: name,
            message: message,
            date: new Date().getTime(),
        })
    }

    function subscribeAcception(callback) {
        onChildAdded(dbAcceptRef, function(data) {
            callback(data.key, data.val());
        })
    }

    function unsubscribe() {
        off(dbAcceptRef, "child_added");
    }

    return {
        send,
        unsubscribe,
        subscribeAcception
    }
}

export default useMessage;