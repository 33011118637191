import Axios from 'axios'
//import store from '@/store'

/* eslint-disable */

//export var accessToken = "";

export class BaseRequest {
    constructor(teamUuid, roomUuid) {
        this.teamUuid = teamUuid
        this.roomUuid = roomUuid
    }

    client() {
        var headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
        return Axios.create({
            baseURL: process.env.VUE_APP_BASE_URL,
            timeout: 1000 * 60 * 1,
            headers: headers
        })
    }
}

export function iconUrl(uuid) {
    return baseUrl + "/resource/channel/" + uuid + "/icon"
}