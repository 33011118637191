<template>
  <div>
    <div class="popup modal is-active">
      <div class="popup__inner room-notice">
        <a href class="modal-heading__btn btn-close" @click.stop.prevent="onClose">
          <i class="fas fa-times"></i>
        </a>
        <div class="ch__main">
          <div class="ch__head">
            <h3 class="ch__name">
              <p>{{ $t("confirm.confirmAudioTitle") }}</p>
            </h3>
            <div class="popup__text popup___notification white-space-pre">
              {{ $t("confirm.confirmAudioMessage") }}
            </div>
            <button
              class="btn btn-primary btn-m margin-auto"
              @click.stop.prevent="onClickOK"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-overlay" class></div>
  </div>
</template>

<script>
export default {
  name: "ChannelStartDialog",
  props: {
    room: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onClickOK() {
      this.$emit("ok");
    },
  },
};
</script>

<style>
.popup___notification {
  text-align: center;
}
</style>
