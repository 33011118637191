<template>
  <div>
    <section class="popup modal popup-msg is-active">
      <div class="popup__inner popup__inner__large">
        <a href class="modal-heading__btn btn-close" @click.stop.prevent="onClose">
          <i class="fas fa-times"></i>
        </a>
        <div class="ch__main">
          <div class="ch__head">
            <h3 class="ch__name">
              <p>{{ $t('message.title') }}</p>
            </h3>
          </div>
          <div class="msg__form">
            <input
              type="text"
              v-model.trim="name"
              :placeholder="$t('message.namePlaceholder')"
            />
            <textarea
              class="message__textarea"
              name
              id
              v-model.trim="message"
              :placeholder="$t('message.messagePlaceholder')"
            ></textarea>
            <button class="btn btn-primary btn-m" :disabled="!isValid" @click="onSend">
              {{ $t('message.send') }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MessageComp',
  data: () => {
    return {
      name: '',
      message: '',
    }
  },
  computed: {
    isValid: function () {
      return this.message.length > 0
    },
  },
  async mounted() {
    await this.$store.dispatch('loadName')
    this.name = this.$store.getters.name
  },
  methods: {
    onSend() {
      this.$emit('send', this.name, this.message)
      this.$store.dispatch('saveName', { name: this.name })
      this.message = ''
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style>
.message__textarea {
  touch-action: manipulation;
}
</style>
