<template>
  <section class="popup modal is-active">
    <div class="popup__inner popup__inner__large">
      <a href class="modal-heading__btn btn-close" @click.stop.prevent="onClose">
        <i class="fas fa-times"></i>
      </a>
      <div class="ch__main">
        <div class="ch__head">
          <div class="ch__text">
            <h3 class="ch__name">
              <p>{{ name }}</p>
            </h3>
            <div class="ch__description" v-html="description"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import useUtility from "../lib/util";

export default {
  name: "ChannelDetail",
  props: {
    name: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    autoLink: function (text) {
      return useUtility().autoLink(text);
    },
  },
};
</script>
