<template>
  <div v-if="channel">
    <section class="modal modal-watching is-active" v-if="isFullscreen">
      <div class="modal-watching__inner message_modal">
        <div class="watching-head">
          <a
            href
            class="modal-heading__btn btn-open"
            @click.stop.prevent="isFullscreen = false"
          >
            <i class="fas fa-angle-down"></i>
          </a>
          <a
            href=""
            class="modal-heading__btn btn-close"
            @click.stop.prevent="isConfirmDialogOpened = true"
            ><i class="fas fa-times"></i
          ></a>
          <!--
          <div class="onair-head__btn">
            <a
              href
              class="modal-heading__btn btn-close"
              @click.stop.prevent="isConfirmDialogOpened = true"
            ></a>
           
          </div>
           -->
        </div>
        <div v-if="banners.length > 0 && useBanner">
          <banner-comp :banners="banners" />
        </div>
        <div class="ch__main">
          <div class="ch__head">
            <div class="ch__text">
              <h3 class="ch__name">
                <div class="onair-head_detail">
                  <span class="status">LIVE</span>
                </div>
                <p>{{ channel.name }}</p>
              </h3>
              <div
                class="ch__description"
                @click.stop.prevent="isChannelDetailOpened = true"
              >
                <p>{{ channel.description }}</p>
              </div>
            </div>
          </div>
          <div class="ch__meta">
            <div class="ch__meta-count">
              <div class="view">
                <i class="fas fa-users"></i>
                <span class="label">{{
                  $t('channel.audienceNumber', {
                    number: audienceNumber,
                  })
                }}</span>
              </div>
              <div class="heart">
                <i class="fas fa-heart"></i>
                <span class="label">{{ $t('channel.like') }}</span>
                <span>{{ likeNumber }}</span>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div v-if="pushMessage.length > 0 || pushImage.length > 0" class="ch__push">
              <push-message-comp :message="pushMessage" :imageUrl="pushImage" />
              <!--
              <template v-if="pushMessage.imageUrl && pushMessage.message.length > 0">
                <image-message-notification
                  :message="pushMessage.message"
                  :image="pushMessage.imageUrl"
                />
              </template>
              <template v-else-if="pushMessage.imageUrl">
                <image-notification :image="pushMessage.imageUrl" />
              </template>
              <template v-else-if="pushMessage.message.length > 0">
                <message-notification :message="pushMessage.message" />
              </template>
               -->
            </div>
          </transition>
          <ul class="ch__thumb__sub">
            <li v-for="distributor in channel.distributors" :key="distributor.uuid">
              <div class="sub-img">
                <img :src="distributor.imageUrl" :alt="distributor.name" loading="lazy" />
              </div>
              <div class="sub-caption">{{ distributor.name }}</div>
            </li>
          </ul>
          <div class="ch__btn">
            <div class="ch__message white-space-pre" v-if="channel.useMessage">
              <a href @click.stop.prevent="isMessageDialogOpened = true">
                <p class="accept__message">
                  {{ $t('channel.messsageMe') }}
                </p>
                <i>
                  <img src="@/assets/img/icon-material-message.svg" />
                </i>
                <div class="ch__btn__name">{{ channel.message }}</div>
              </a>
            </div>
            <div class="ch__like">
              <div
                class="ch__like__btn"
                @click="onPushLike"
                :class="{ clicked: likeClicked }"
              >
                <svg
                  class="likeButton"
                  :class="{ clicked: likeClicked }"
                  width="113px"
                  height="113px"
                  viewBox="0 0 500 500"
                >
                  <circle class="explosion" r="150" cx="250" cy="250" />
                  <g class="particleLayer">
                    <circle fill="#8CE8C3" cx="130" cy="126.5" r="12.5" />
                    <circle fill="#8CE8C3" cx="411" cy="313.5" r="12.5" />
                    <circle fill="#91D2FA" cx="279" cy="86.5" r="12.5" />
                    <circle fill="#91D2FA" cx="155" cy="390.5" r="12.5" />
                    <circle fill="#CC8EF5" cx="89" cy="292.5" r="10.5" />
                    <circle fill="#9BDFBA" cx="414" cy="282.5" r="10.5" />
                    <circle fill="#9BDFBA" cx="115" cy="149.5" r="10.5" />
                    <circle fill="#9FC7FA" cx="250" cy="80.5" r="10.5" />
                    <circle fill="#9FC7FA" cx="78" cy="261.5" r="10.5" />
                    <circle fill="#96D8E9" cx="182" cy="402.5" r="10.5" />
                    <circle fill="#CC8EF5" cx="401.5" cy="166" r="13" />
                    <circle fill="#DB92D0" cx="379" cy="141.5" r="10.5" />
                    <circle fill="#DB92D0" cx="327" cy="397.5" r="10.5" />
                    <circle fill="#DD99B8" cx="296" cy="392.5" r="10.5" />
                  </g>
                  <path
                    class="heart"
                    d="M250,187.4c-31.8-47.8-95.5-19.8-95.5,32.2c0,35.2,31.8,60.3,55.7,79.2c24.9,19.7,31.8,23.9,39.8,31.8 c7.9-7.9,14.6-12.6,39.8-31.8c24.3-18.5,55.7-44.4,55.7-79.6C345.5,167.6,281.8,139.7,250,187.4z"
                  />
                </svg>
              </div>
              <div class="ch__like__number">{{ likeNumber }}</div>
            </div>
            <div
              class="ch__reaction"
              v-if="
                (channel.reactionIcons && channel.reactionIcons.length > 0) ||
                channel.useMessage == true
              "
            >
              <a
                href
                @click.stop.prevent="isReactionOpened = true"
                v-if="channel.reactionIcons && channel.reactionIcons.length > 0"
              >
                <i class>
                  <img src="@/assets/img/icon-feather-smile.svg" alt />
                </i>
                <div class="ch__btn__name">{{ $t('channel.reaction') }}</div>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="channel.questionnaireUrl && channel.questionnaireUrl.length > 0"
          class="footer__channel__questionnaire"
        >
          <a :href="channel.questionnaireUrl" target="_blank">
            <template v-if="channel.questionnaireLabel.length > 0">
              {{ channel.questionnaireLabel }}
            </template>
            <template v-else> {{ $t('channel.questionnaire') }} </template>
          </a>
        </div>
        <div class="ch__faq">
          <template v-if="$i18n.locale == 'ja'">
            <a href="https://www.cheerphone.jp/faq" target="_blank">
              {{ $t('channel.faq') }}
            </a>
          </template>
          <template v-else>
            <a href="https://www.cheerphone.jp/faq-en" target="_blank">
              {{ $t('channel.faq') }}
            </a>
          </template>
        </div>
      </div>
    </section>
    <!-- //.modal-watching-->
    <section class="footer-watching" v-else>
      <div class="footer-watching__inner"></div>
      <a
        href
        class="modal-heading__btn btn-open"
        @click.stop.prevent="isFullscreen = true"
      >
        <i class="fas fa-angle-up"></i>
      </a>
      <a
        href
        class="modal-heading__btn btn-close"
        @click.stop.prevent="isConfirmDialogOpened = true"
      >
        <i class="fas fa-times"></i>
      </a>
      <h2 class="footer__heading">{{ $t('channel.listening') }}</h2>
      <h3 class="footer__name">{{ channel.name }}</h3>
      <p class="footer__description" @click.stop.prevent="isChannelDetailOpened = true">
        {{ channel.description | truncate(100, '...') }}
      </p>
    </section>
    <channel-start-dialog
      v-if="isChannelStartOpened"
      @ok="isChannelStartOpened = false"
      @close="isChannelStartOpened = false"
    />
    <reaction-dialog
      :reactionIcons="channel.reactionIcons"
      :reactionCounts="reactionCounts"
      v-if="isReactionOpened"
      @reaction="sendReaction"
      @close="isReactionOpened = false"
    />
    <message-comp
      v-if="isMessageDialogOpened"
      @close="isMessageDialogOpened = false"
      @send="onSendMessage"
    />
    <error-dialog
      v-if="isErrorDialogOpened"
      :title="errorDialog.title"
      :message="errorDialog.message"
      @close="isErrorDialogOpened = false"
    />
    <confirm-dialog
      v-if="isConfirmDialogOpened"
      :title="$t('confirm.confirmTitle')"
      :message="$t('confirm.endListeningMessage')"
      @cancel="isConfirmDialogOpened = false"
      @ok="onExit"
    />
    <watch-end-dialog
      ref="ratingDialog"
      :bug-report-url="channel.bugReportUrl"
      :ads="popupAds"
      v-if="isWatchEndDialogOpened"
      @rating="onSendRating"
      @close="onCloseRating"
    />
    <distribution-end-dialog
      v-if="isDistributionEndDialog"
      @ok="
        isDistributionEndDialog = false
        isWatchEndDialogOpened = true
      "
    />
    <channel-detail
      v-if="isChannelDetailOpened"
      :name="channel.name"
      :description="channel.description"
      @close="isChannelDetailOpened = false"
    ></channel-detail>
  </div>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk-ng'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import useMessage from '@/lib/message'
import ReactionDialog from './ReactionDialog.vue'
import ChannelStartDialog from './ChannelStartDialog.vue'
import ConfirmDialog from './ConfirmDialog.vue'
import MessageComp from './MessageComp.vue'
import ErrorDialog from './ErrorDialog.vue'
import WatchEndDialog from './WatchEndDialog.vue'
import DistributionEndDialog from './DistributionEndDialog.vue'
import ChannelRequest from '../net/channel'
import ListnerClient from '../lib/listener'
import ListenerRtmClient, { RtmEvents } from '@/lib/listener-rtm'
import ChannelDetail from './ChannelDetail.vue'
import BannerComp from './BannerComp.vue'
import usePush from '@/lib/push'
import PushMessageComp from './PushMessageComp.vue'

const client = new ListnerClient()
const rtmClient = new ListenerRtmClient()

export default {
  name: 'ChannelComp',
  components: {
    ReactionDialog,
    ChannelStartDialog,
    MessageComp,
    ErrorDialog,
    ConfirmDialog,
    WatchEndDialog,
    DistributionEndDialog,
    ChannelDetail,
    BannerComp,
    PushMessageComp,
  },
  props: {
    teamUUID: {
      type: String,
      require: true,
    },
    roomUUID: {
      type: String,
      require: true,
    },
    channel: {
      type: Object,
      default: undefined,
    },
    useBanner: {
      type: Boolean,
      default: false,
    },
    banners: {
      type: Array,
    },
  },
  computed: {
    channelRequest: function () {
      return new ChannelRequest(this.teamUUID, this.roomUUID, this.channel.uuid)
    },
  },
  data: () => {
    return {
      likeNumber: 0,
      audienceNumber: 0,
      reactionCounts: {},
      isFullscreen: true,
      isReactionOpened: false,
      isChannelStartOpened: false,
      isChannelDetailOpened: false,
      isErrorDialogOpened: false,
      isConfirmDialogOpened: false,
      isMessageDialogOpened: false,
      isWatchEndDialogOpened: false,
      isDistributionEndDialog: false,
      likeClicked: false,
      errorDialog: {
        title: '',
        message: '',
      },
      popupAds: [],
      showPushMessage: false,
      pushMessage: '',
      pushImage: '',
    }
  },
  async mounted() {
    this.likeNumber = this.channel.like
    this.audienceNumber = this.channel.audienceNumber
    var self = this
    AgoraRTC.onAutoplayFailed = () => {
      self.isChannelStartOpened = true
    }
    if (await this.connectToAgora()) {
      let ads = await this.channelRequest.listPopup()
      this.popupAds = ads.ads
      const modal = document.querySelector('.message_modal')
      disableBodyScroll(modal)
      usePush(this.channel.uuid).pushSubscribe(this.onPushMessageReceived)
    } else {
      /** */
      this.$emit('exit')
    }
  },
  beforeDestroy() {
    clearAllBodyScrollLocks()
    this.onExit()
  },
  methods: {
    async connectToAgora() {
      try {
        let token = await this.channelRequest.enterAsListner()
        await this.startListener(token)
        await this.startRTM(token)
        return true
      } catch (e) {
        console.log(e)
        if (
          e.response &&
          e.response.data &&
          e.response.data.code == 'audience_limit_over'
        ) {
          alert(this.$t('error.audienceLimitError'))
        }
      }
      return false
    },
    async startListener(token) {
      try {
        await client.start(
          process.env.VUE_APP_AGORA_ID,
          this.channel.uuid,
          token.agoraToken,
          token.accessUser
        )
      } catch (e) {
        console.error(e)
      }
    },
    async startRTM(token) {
      await rtmClient.start(token.userUuid, token.rtmToken, this.channel.uuid)
      rtmClient.on(RtmEvents.UpdateChannelStatus, this.updateStatus.bind(this))
      rtmClient.on(RtmEvents.DistributionEnd, this.distributionEnd.bind(this))
    },
    async sendReaction(reaction) {
      try {
        let res = await this.channelRequest.sendReaction({
          items: [{ id: reaction.id, count: 1 }],
        })
        let self = this
        res.reactions.forEach((x) => {
          self.reactionCounts[x.id] = {
            number: x.count,
          }
        })
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    },
    async onPushLike() {
      this.likeClicked = true
      let self = this
      setTimeout(() => {
        self.likeClicked = false
      }, 500)
      try {
        let res = await this.channelRequest.like({
          count: 1,
        })
        this.likeNumber = res.likeNumber
      } catch (e) {
        console.log(e)
      }
    },
    onExit() {
      rtmClient.end()
      client.end()
      useMessage(this.channel.uuid).unsubscribe()
      this.isConfirmDialogOpened = false
      if (this.popupAds.length > 0) {
        this.isWatchEndDialogOpened = true
      } else {
        this.$emit('exit')
      }
    },
    async onCloseRating() {
      this.isWatchEndDialogOpened = false
      this.$emit('exit')
    },
    async onSendRating(rating) {
      console.log(rating)
      try {
        await this.channelRequest.sendRating({ rating: Number(rating) })
      } catch (e) {
        console.log(e)
      }
      this.$refs.ratingDialog.sentSuccess()
    },
    onSendMessage(name, message) {
      const { send } = useMessage(this.channel.uuid)
      send(name, message)
      this.isMessageDialogOpened = false
    },
    onPushMessageReceived(key, message) {
      if (new Date().getTime() - message.date < 60 * 5 * 1000) {
        console.log(message)
        this.pushMessage = message.message
        this.pushImage = message.imageUrl
        /*
        this.$set(this.pushMessage, 'imageUrl', message.imageUrl)
        this.$set(this.pushMessage, 'message', message.message)
        */
        console.log('after', this.pushMessage)
        this.showPushMessage = true
      }
    },
    updateStatus(status) {
      this.audienceNumber = status.audienceNumber
      this.likeNumber = status.likeNumber
      this.reactionCounts = status.reactions.reduce((a, x) => {
        a[x.id] = x
        return a
      }, {})
    },
    async distributionEnd() {
      await rtmClient.end()
      await client.end()
      this.isDistributionEndDialog = true
    },
  },
}
</script>

<style>
.modal-watching__inner {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 100%;
}

.accept__message {
  border: 1px solid #ee0000;
  border-radius: 8px;
  margin: 10px;
}
.footer__channel__questionnaire {
  color: #ff7272;
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
}
.footer__channel__questionnaire a {
  text-decoration: underline;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.5s;
}
/.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-active {
  transition: all 0.5s;
}

.fade-leave-to {
  opacity: 0;
}

.ch__push {
  margin-top: 20px;
}

.ch__faq {
  text-align: center;
  margin-top: 25px;
}

.ch__faq a {
  color: white;
  background: gray;
  border-radius: 5px;
  padding: 5px 25px;
}
</style>
