const storeKey = "status"

const Status = {
    namespaces: true,
    state: {
        readRoomNotes: []
    },
    getters: {
        readRoomNotes: (state) => {
            return state.readRoomNotes;
        }
    },
    mutations: {
        READ_ROOM_NOTE: (state, { uuid }) => {
            if (state.readRoomNotes.length > 0 &&
                Array(state.readRoomNote).indexOf(uuid) >= 0) {
                return
            }
            state.readRoomNotes.push(uuid)
            localStorage.setItem(storeKey, JSON.stringify(state))
        },
        LOAD_ROOM_NOTES: (state) => {
            const status = localStorage.getItem(storeKey)
            if (status) {
                Object.assign(state, JSON.parse(status))
            }
        }
    },
    actions: {
        readRoomNote: ({ commit }, { uuid }) => {
            commit('READ_ROOM_NOTE', { uuid });
        },
        loadRoomNotes: ({ commit }) => {
            commit('LOAD_ROOM_NOTES');
        }
    }
}
export default Status;