<template>
  <div class="notification-container">
    <div class="image-container" @click="isFullscreenOpend = true">
      <img :src="image" alt="" />
    </div>
    <div class="text-container">
      <h2>{{ $t('push.fromDistributor') }}</h2>
      <p v-html="autolinkedMessage"></p>
    </div>
    <fullscreen-image
      :image="image"
      v-if="isFullscreenOpend"
      @close="isFullscreenOpend = false"
    />
  </div>
</template>

<script>
import FullscreenImage from '../FullscreenImage.vue'
import Autolinker from 'autolinker'

export default {
  components: {
    FullscreenImage,
  },
  data: () => ({
    isFullscreenOpend: false,
  }),
  props: {
    image: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    autolinkedMessage() {
      return Autolinker.link(this.message, {
        stripPrefix: false,
        stripTrailingSlash: false,
        newWindow: true,
        truncate: {
          length: 30,
          location: 'smart',
        },
      })
    },
  },
}
</script>

<style scoped>
.notification-container {
  display: flex;
  /*justify-content: center; */
  align-items: center;
  width: 100%;
  height: 100px;
  background: rgba(242, 242, 242, 1);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.25);
}

.image-container {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}

.image-container img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.text-container {
  text-align: left;
  width: calc(100% - 100px);
}

.text-container h2 {
  font-size: 1em;
  color: black;
  text-decoration: underline;
}

.text-container p {
  font-size: 0.8em;
  white-space: pre-wrap;
  color: black;
}
</style>
