import { render, staticRenderFns } from "./Audience.vue?vue&type=template&id=4e31db1e"
import script from "./Audience.vue?vue&type=script&lang=js"
export * from "./Audience.vue?vue&type=script&lang=js"
import style0 from "./Audience.vue?vue&type=style&index=0&id=4e31db1e&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports