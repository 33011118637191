<template>
  <div class="container" @click="$emit('close')">
    <img :src="image" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

img {
  max-width: 100%;
  max-height: 100%;
}
</style>
