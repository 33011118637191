import { BaseRequest } from '@/net/axios'

export default class ChannelRequest extends BaseRequest {
  constructor(teamUuid, roomUuid, channelUuid) {
    super(teamUuid, roomUuid)
    this.channelUuid = channelUuid
  }

  async fetch() {
    let res = await this.client().get(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}`
    )
    return res.data
  }

  async getPublisherToken() {
    let res = await this.client().get(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/publisher_token`
    )
    return res.data
  }

  async getToken() {
    let res = await this.client().get(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/token`
    )
    return res.data
  }

  async status() {
    let res = await this.client().get(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/status`
    )
    return res.data
  }

  async listChannelDistributors() {
    let res = await this.client().get(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/distributor`
    )
    return res.data
  }

  async enterAsListner() {
    let res = await this.client().post(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/listener`
    )
    return res.data
  }

  async like(params) {
    let res = await this.client().post(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/like`,
      params
    )
    return res.data
  }

  async sendReaction(params) {
    let res = await this.client().post(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/reaction`,
      params
    )
    return res.data
  }

  async sendRating(params) {
    let res = await this.client().post(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/evaluation`,
      params
    )
    return res.data
  }

  async checkAccessCode(accessCode) {
    let res = await this.client().post(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/acl`,
      {
        accessCode: accessCode,
      }
    )
    return res.data
  }

  async listPopup() {
    let res = await this.client().get(
      `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel/${this.channelUuid}/popup`
    )
    return res.data
  }
}
