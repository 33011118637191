var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.room)?_c('div',[_c('header',[_c('div',{staticClass:"container"},[(_vm.room.useBanner && !_vm.room.bannerUnderRoomImage)?_c('div',{staticClass:"banner_top"},[_c('banner-comp',{attrs:{"banners":_vm.banners}})],1):_vm._e(),_c('div',{staticClass:"room-img"},[_c('img',{attrs:{"src":_vm.room.imageUrl,"alt":_vm.$t('audience.roomImageAlt')}})]),_c('h1',{staticClass:"room-name"},[_vm._v(_vm._s(_vm.room.name))]),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.room.description)}}),(_vm.room.useBanner && _vm.room.bannerUnderRoomImage)?_c('div',[_c('banner-comp',{attrs:{"banners":_vm.banners}})],1):_vm._e()])]),_c('main',[_c('div',{staticClass:"container"},[_c('section',{staticClass:"channel",attrs:{"id":"channel"}},[_c('h2',{staticClass:"heading_title"},[_vm._v(_vm._s(_vm.$t('audience.channelList')))]),_c('p',{staticClass:"heading_text"},[_vm._v(_vm._s(_vm.$t('audience.channelSelectMessage')))]),_c('div',{staticClass:"channel__list"},_vm._l((_vm.channels),function(channel){return _c('div',{key:channel.uuid,staticClass:"ch-list__item",class:{
                'is-active': channel.onAir,
                closed: !channel.onAir,
              }},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onSelectChannel(channel)}}},[(channel.onAir)?_c('div',{staticClass:"onair-head_detail"},[_c('span',{staticClass:"status"},[_vm._v("LIVE")])]):_vm._e(),_c('div',{staticClass:"ch-list__thumb"},[(channel.displayIcon)?[_c('img',{staticClass:"ch-list__icon",attrs:{"src":channel.iconUrl,"alt":channel.name}})]:_vm._l((channel.distributors),function(distributor){return _c('img',{key:distributor.uuid,attrs:{"src":distributor.imageUrl,"alt":distributor.name}})})],2),_c('div',{staticClass:"ch-list__detail"},[_c('h3',{staticClass:"ch-list__name"},[_vm._v(_vm._s(channel.name))]),_c('p',{staticClass:"ch-list__description",domProps:{"innerHTML":_vm._s(channel.description)}}),_c('div',{staticClass:"ch-list__meta"},[_c('div',{staticClass:"ch-list__meta-count"},[_c('div',{staticClass:"view"},[_c('i',{staticClass:"fas fa-users"}),_c('span',[_vm._v(_vm._s(channel.audienceNumber))])]),_c('div',{staticClass:"heart"},[_c('i',{staticClass:"fas fa-heart"}),_c('span',[_vm._v(_vm._s(channel.like))])])]),(
                        (!_vm.selectedChannel || _vm.selectedChannel.uuid == channel.uuid) &&
                        channel.onAir
                      )?_c('a',{staticClass:"btn btn-primary btn-ss",attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('audience.startListening')))]):_vm._e()])])])])}),0)]),(_vm.room.questionnaireLabel)?_c('div',{staticClass:"footer_notice fixed"},[_c('a',{attrs:{"href":_vm.questionnaireUrl}},[_vm._v(_vm._s(_vm.room.questionnaireLabel))])]):_vm._e()]),(_vm.selectedChannel)?_c('channel-comp',{attrs:{"teamUUID":_vm.teamUUID,"roomUUID":_vm.roomUUID,"channel":_vm.selectedChannel,"banners":_vm.banners,"useBanner":_vm.selectedChannel.useBanner},on:{"exit":_vm.onExitChannel}}):_vm._e(),(_vm.isNoticeDialogOpend)?_c('notice-dialog',{attrs:{"room":_vm.room},on:{"close":_vm.onClickNoticeDialog}}):_vm._e(),(_vm.isAccessCodeOpend)?_c('access-code-dialog',{on:{"ok":_vm.velifyAccessCocde,"cancel":function($event){_vm.isAccessCodeOpend = false}}}):_vm._e(),(_vm.isErrorDialogOpened)?_c('error-dialog',{attrs:{"title":_vm.errorDialog.title,"message":_vm.errorDialog.message},on:{"close":function($event){_vm.isErrorDialogOpened = false}}}):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }