const storeKey = "message"

const Message = {
    namespaces: true,
    state: {
        name: ""
    },
    getters: {
        name: (state) => {
            return state.name;
        }
    },
    mutations: {
        SAVE_NAME: (state, { name }) => {
            state.name = name;
            localStorage.setItem(storeKey, JSON.stringify(state))
        },
        LOAD_NAME: (state) => {
            const message = localStorage.getItem(storeKey)
            if (message) {
                Object.assign(state, JSON.parse(message))
            }
        }
    },
    actions: {
        saveName: ({ commit }, { name }) => {
            commit('SAVE_NAME', { name });
        },
        loadName: ({ commit }) => {
            commit('LOAD_NAME');
        }
    }
}
export default Message;