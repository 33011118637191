<template>
  <div>
    <div class="image-container">
      <img :src="image" alt="" @click="isFullscreenOpend = true" />
    </div>
    <fullscreen-image
      :image="image"
      v-if="isFullscreenOpend"
      @close="isFullscreenOpend = false"
    />
  </div>
</template>

<script>
import FullscreenImage from "../FullscreenImage.vue";

export default {
  components: {
    FullscreenImage,
  },
  data: () => ({
    isFullscreenOpend: false,
  }),
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.image-container {
  width: 100%;
  height: 120px;
  display: flex;
  background: rgba(242, 242, 242, 1);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
