<template>
  <section class="popup modal is-active">
    <div class="popup__inner popup__inner__medium">
      <a href class="modal-heading__btn btn-close" @click.stop.prevent="onClose">
        <i class="fas fa-times"></i>
      </a>
      <div class="ch__head">
        <h3 class="popup__title">{{ $t('common.reaction') }}</h3>
      </div>
      <div class="reaction">
        <ul class="reaction__list">
          <li v-for="reaction in reactionIcons" :key="reaction.id">
            <div class="reaction__name">{{ reaction.name }}</div>
            <div
              class="reaction__number"
              v-if="reactionCounts && reactionCounts[reaction.id]"
            >
              {{ reactionCounts[reaction.id].number }}
            </div>
            <a class="reaction__icon" @click.stop.prevent="onSendReaction(reaction)">
              <img :src="reaction.imageUrl" :alt="reaction.name" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ReactionDialog',
  props: {
    reactionIcons: {
      type: Array,
    },
    reactionCounts: {
      type: Object,
    },
  },
  data: () => {
    return {}
  },
  mounted() {},
  methods: {
    onClose() {
      this.$emit('close')
    },
    onSendReaction(reaction) {
      this.$emit('reaction', reaction)
    },
  },
}
</script>

<style>
.reaction__icon {
  width: 64px;
  height: 64px;
}
</style>
