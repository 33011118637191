import EventEmitter from 'events'
import RtmClient from "@/lib/rtm-client";

export const RtmEvents = {
    DistributionEnd: "DistributionEnd",
    UpdateChannelStatus: "UpdateChannelStatus"
}

export default class ListenerRtmClient extends EventEmitter {
    constructor() {
        super()
        this.client = new RtmClient();
        this.channel = ""
        this.isRunning = false;
    }

    async start(user, token, channel) {
        this.client.init();
        let self = this;
        this.channel = channel
        this.client.login(user, token).then(() => {
            self._logined = true;
            self.joinChannel(channel)
        });
        this.isRunning = true;
    }

    joinChannel(channel) {
        let self = this;
        this.client.joinChannel(channel)
            .then(() => {
                self.waitDataChannelMessage();
            })
            .catch((e) => {
                // TODO: エラー。
                console.log(e);
            })
    }

    async end() {
        if (this.isRunning) {
            await this.client.logout();
            this.isRunning = false;
        }
    }

    waitDataChannelMessage() {
        let self = this;
        this.client.on("ChannelMessage", async({ args }) => {
            const [message] = args;
            if (message.messageType === "TEXT") {
                self.handleMessage(JSON.parse(message.text));
            }
        });
    }

    handleMessage(message) {
        if (message.type === "finish") {
            this.emit(RtmEvents.DistributionEnd)
        } else if (message.type == "update") {
            this.emit(RtmEvents.UpdateChannelStatus, message.status)
        }
    }
}