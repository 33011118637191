import AgoraRTM from 'agora-rtm-sdk'
import EventEmitter from 'events'

const appId = process.env.VUE_APP_AGORA_ID

export default class RTMClient extends EventEmitter {
    constructor() {
        super()
        this.channels = {}
        this._logined = false
    }

    init() {
        this.client = AgoraRTM.createInstance(appId, { enableLogUpload: false })
        this.subscribeClientEvents()
    }

    // subscribe client events
    subscribeClientEvents() {
        const clientEvents = [
            'ConnectionStateChanged',
            'MessageFromPeer'
        ]
        clientEvents.forEach((eventName) => {
            this.client.on(eventName, (...args) => {
                console.log('emit ', eventName, ...args)
                    // log event message
                this.emit(eventName, ...args)
            })
        })
    }

    // subscribe channel events
    subscribeChannelEvents(channelName) {
        const channelEvents = [
            'ChannelMessage',
            'MemberJoined',
            'MemberLeft'
        ]
        channelEvents.forEach((eventName) => {
            this.channels[channelName].channel.on(eventName, (...args) => {
                console.log('emit ', eventName, args)
                this.emit(eventName, { channelName, args: args })
            })
        })
    }

    async login(accountName, token) {
        this.accountName = accountName
        return this.client.login({ uid: this.accountName, token })
    }

    async logout() {
        return this.client.logout()
    }

    async joinChannel(name) {
        console.log('joinChannel', name)
        const channel = this.client.createChannel(name)
        this.channels[name] = {
            channel,
            joined: false // channel state
        }
        this.subscribeChannelEvents(name)
        return channel.join()
    }

    async leaveChannel(name) {
        console.log('leaveChannel', name)
        if (!this.channels[name] ||
            (this.channels[name] &&
                !this.channels[name].joined)) return
        return this.channels[name].channel.leave()
    }

    async queryPeersOnlineStatus(memberId) {
        console.log('queryPeersOnlineStatus', memberId)
        return this.client.queryPeersOnlineStatus([memberId])
    }
}