import { ref, push, set, onChildAdded, off, get } from 'firebase/database'
import { db, auth } from '@/lib/firebase'

const usePush = (channelUuid) => {
  const dbRef = ref(db, `${channelUuid})-push`)

  async function sendPush(imageUrl, message) {
    const postRef = push(dbRef)
    await set(postRef, {
      uid: auth.currentUser ? auth.currentUser.uid : null,
      imageUrl: imageUrl,
      message: message,
      date: new Date().getTime(),
    })
  }

  async function getPush() {
    let snapshot = await get(dbRef)
    if (snapshot.exists()) {
      var val = snapshot.val()
      var data = []
      for (var key in val) {
        data.push({
          uid: val[key].uid,
          imageUrl: val[key].imageUrl,
          message: val[key].message,
          date: val[key].date,
        })
      }
      return data
    } else {
      return []
    }
  }

  function pushSubscribe(callback) {
    onChildAdded(dbRef, function(data) {
      callback(data.key, data.val())
    })
  }

  function pushUnsubscribe() {
    off(dbRef, 'child_added')
  }

  return {
    sendPush,
    getPush,
    pushSubscribe,
    pushUnsubscribe,
  }
}

export default usePush
