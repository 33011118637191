const storeKey = "user"

const User = {
    namespaces: true,
    state: {
        uuid: ""
    },
    getters: {
        isAvailable: (state) => {
            return state.uuid && state.uuid.length > 0
        },
        uuid: (state) => {
            return state.uuid;
        }
    },
    mutations: {
        SAVE_USER: (state, { uuid }) => {
            state.uuid = uuid
            sessionStorage.setItem(storeKey, JSON.stringify(state))
        },
        LOAD_USER: (state) => {
            const user = sessionStorage.getItem(storeKey)
            if (user) {
                Object.assign(state, JSON.parse(user))
            }
        },
    },
    actions: {
        saveUser: ({ commit }, { uuid }) => {
            commit('SAVE_USER', { uuid })
        },
        loadUser: ({ commit }) => {
            commit('LOAD_USER')
        }
    },
}

export default User;