<template>
  <div>
    <div class="popup modal is-active">
      <div class="popup__inner">
        <h2 class="popup__title">{{ $t("accessCode.title") }}</h2>
        <p class="popup__text">
          {{ $t("accessCode.message") }}
        </p>
        <div class="popup__access__code_input">
          <input type="text" placeholder="アクセスコード" v-model="accessCode" />
        </div>
        <div class="popup__buttons">
          <div class="popup__button">
            <input
              type="button"
              class="button"
              :value="$t('common.cancel')"
              @click="onCancelClicked"
            />
          </div>
          <div class="popup__button">
            <input type="button" class="button" value="OK" @click="onOKClicked" />
          </div>
        </div>
      </div>
    </div>
    <div id="modal-overlay" class></div>
  </div>
</template>

<script>
export default {
  name: "AccessCodeDialog",
  data: () => {
    return {
      accessCode: "",
    };
  },
  methods: {
    onCancelClicked() {
      this.$emit("cancel");
    },
    onOKClicked() {
      if (this.accessCode.length > 0) {
        this.$emit("ok", this.accessCode);
      }
    },
  },
};
</script>

<style scoped>
.popup__access__code_input {
  margin: 15px 0;
}
</style>
