import { BaseRequest } from '@/net/axios'

export default class RoomRequest extends BaseRequest {
  async fetch() {
    try {
      const res = await this.client().get(
        `/1/team/${this.teamUuid}/room/${this.roomUuid}`
      )
      return res.data
    } catch (e) {
      console.log(e.message)
      throw e
    }
  }

  async fetchUser(audienceUuid) {
    try {
      const res = await this.client().get(
        `/1/team/${this.teamUuid}/room/${this.roomUuid}/audience/${audienceUuid}`
      )
      return res.data
    } catch (e) {
      console.log(e.message)
      throw e
    }
  }

  async enter(params) {
    try {
      const res = await this.client().post(
        `/1/team/${this.teamUuid}/room/${this.roomUuid}/audience`,
        params
      )
      return res.data
    } catch (e) {
      console.log(e.message)
      throw e
    }
  }

  async listChannels() {
    try {
      let res = await this.client().get(
        `/1/team/${this.teamUuid}/room/${this.roomUuid}/channel`
      )
      return res.data
    } catch (e) {
      console.log(e.message)
      throw e
    }
  }

  async listBanners() {
    try {
      let res = await this.client().get(
        `/1/team/${this.teamUuid}/room/${this.roomUuid}/banner`
      )
      return res.data.banners
    } catch (e) {
      console.log(e.message)
      throw e
    }
  }
}
