<template>
  <div>
    <div class="popup modal is-active">
      <div class="popup__inner">
        <h2 class="popup__title">{{ title }}</h2>
        <p class="popup__text">{{ message }}</p>
        <div class="popup__buttons">
          <div class="popup__button">
            <input
              type="button"
              class="button"
              :value="$t('common.cancel')"
              @click="onCancelClicked"
            />
          </div>
          <div class="popup__button">
            <input type="button" class="button" value="OK" @click="onOKClicked" />
          </div>
        </div>
      </div>
    </div>
    <div id="modal-overlay" class></div>
  </div>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  methods: {
    onCancelClicked() {
      this.$emit("cancel");
    },
    onOKClicked() {
      this.$emit("ok");
    },
  },
};
</script>

<style>
.popup__buttons {
  display: flex;
}
.popup__button {
  padding: 2px;
}
.popup__close {
  position: relative;
}

.popup__close__button {
  position: absolute;
  right: 0px;
}
</style>
