const useUtility = () => {
  function autoLink(text) {
    return text.replace(
      /(https?:\/\/[^\s]*)/g,
      "<a href='$1' target='_blank'>$1</a>"
    )
  }

  return {
    autoLink,
  }
}

export default useUtility
