import Vue from 'vue'
import Vuex from 'vuex'

import User from "./user";
import Message from "./message";
import Status from "./status";

Vue.use(Vuex)

/* eslint-disable */

const store = new Vuex.Store({
    modules: {
        User,
        Message,
        Status
    }
})

export default store;