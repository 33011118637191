<template>
  <div>
    <div class="popup modal is-active">
      <div class="popup__inner">
        <h2 class="popup__title">{{ $t("confirm.distributionEndTitle") }}</h2>
        <p class="popup__text">{{ $t("confirm.distributionEndMessage") }}</p>
        <div class="popup__button">
          <input type="button" class="button" value="OK" @click="onOKClicked" />
        </div>
      </div>
    </div>
    <div id="modal-overlay" class=""></div>
  </div>
</template>

<script>
export default {
  name: "DistributionEndDialog",
  methods: {
    onOKClicked() {
      this.$emit("ok");
    },
  },
};
</script>
