<template>
  <div class="text-container">
    <h2>{{ $t('push.fromDistributor') }}</h2>
    <p v-html="autolinkedMessage"></p>
  </div>
</template>

<script>
import Autolinker from 'autolinker'

export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    autolinkedMessage() {
      return Autolinker.link(this.message, {
        stripPrefix: false,
        stripTrailingSlash: false,
        newWindow: true,
        truncate: {
          length: 30,
          location: 'smart',
        },
      })
    },
  },
}
</script>

<style scoped>
.text-container {
  width: 95%;
  height: 100%;
  background: rgba(242, 242, 242, 1);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.25);
  padding: 10px;
  text-align: center;
}

.text-container h2 {
  font-size: 1em;
  color: black;
  text-decoration: underline;
}

.text-container p {
  font-size: 0.8em;
  white-space: pre-wrap;
  color: black;
}
</style>
