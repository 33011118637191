<template>
  <div>
    <div class="popup modal is-active">
      <div class="popup__inner">
        <h2 class="popup__title" v-if="title.length > 0">{{ title }}</h2>
        <p class="popup__text">
          {{ message }}
        </p>
        <div class="popup__button">
          <input type="button" class="button" value="OK" @click="onOKClicked" />
        </div>
      </div>
    </div>
    <div id="modal-overlay" class=""></div>
  </div>
</template>

<script>
export default {
  name: "ErrorDialog",
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  methods: {
    onOKClicked() {
      this.$emit("close");
    },
  },
};
</script>
