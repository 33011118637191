export const genderOptions = [
  {
    value: 'male',
    name: '男性',
  },
  {
    value: 'female',
    name: '女性',
  },
  {
    value: 'other',
    name: 'その他',
  },
]

export const generationOptions = [
  {
    value: 0,
    name: '10代以下',
  },
  {
    value: 1,
    name: '20代',
  },
  {
    value: 2,
    name: '30代',
  },
  {
    value: 3,
    name: '40代',
  },
  {
    value: 4,
    name: '50代',
  },
  {
    value: 5,
    name: '60代以上',
  },
]
