<template>
  <p class="not-found">
    {{ $t('notFound') }}
  </p>
</template>

<script>
export default {
  name: 'NotFound',
  mounted() {
    document.body.className = 'p-notfound'
    this.$root.headerHide = true
    this.$root.footerHide = true
  },
  beforeDestroy() {
    document.body.className = ''
    this.$root.headerHide = false
    this.$root.footerHide = false
  },
}
</script>

<style scoped>
.not-found {
  white-space: pre-wrap;
}
</style>
